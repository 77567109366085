






























































import {
  defineComponent, computed, useContext, PropType, toRefs,
} from '@nuxtjs/composition-api';
import {
  SfButton, SfProductCardHorizontal, SfProperty,
} from '~/components';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

import { useImage } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import { useUser } from '~/modules/customer/composables/useUser';
import { useProductsWithCommonProductCardProps } from './useProductsWithCommonCardProps';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';

export default defineComponent({
  components: {
    CategoryProductPrice,
    SfProductCardHorizontal,
    SfButton,
    SfProperty,
    SkeletonLoader,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
  },
  emits: ['click:wishlist', 'click:add-to-cart'],
  setup(props) {
    const context = useContext();

    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);

    const productsFormatted = computed(() => productsWithCommonProductCardProps.value.map((product) => {
      const label = product.commonProps.isInWishlist
        ? 'Remove from Wishlist'
        : 'Save for later';

      return {
        ...product,
        wishlistMessage: context.i18n.t(label),
      };
    }));

    const { imageSizes: { productCardHorizontal: imageSize } } = useImage();

    const { isAuthenticated } = useUser();

    return {
      productsFormatted,
      imageSize,
      isAuthenticated,
    };
  },
});
